import React from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import SEO from '../components/seo'
import Layout from '../components/layout'
import {
  BodyContainer,
  PageContainerStyles,
  StyledSectionTwo,
} from '../components/pageStyles'

const StyledImg = styled(GatsbyImage)`
  flex: 1 1 40%;
  max-height: 400px;
  width: 100%;
  /*display: block;*/ /*remove inline-block spaces*/
  object-fit: contain;
  text-align: center;
`

const Section = styled.div`
  padding-top: 80px;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column-reverse;
  align-items: center;
  padding-bottom: 1rem;
  width: 100%;
  min-height: 300px;
  @media (min-width: 576px) {
    flex-direction: row;
  }
  /* @media (min-width: 768px) { ... } */
  /* @media (min-width: 992px) { ... } */
  @media (min-width: 1200px) {
  }
`

const homeQuery = graphql`
  query HomepageQuery {
    homeJson {
      title
      content {
        childMarkdownRemark {
          html
          rawMarkdownBody
        }
      }
      about {
        content {
          childMarkdownRemark {
            html
          }
        }
      }
      section {
        heading
        body
        foot
        image {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED
              quality: 90
              height: 400
              width: 400
              formats: [WEBP]
            )
          }
        }
      }
      cover {
        title
        image {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              quality: 90
              height: 300
              formats: [WEBP]
            )
          }
        }
      }
    }
  }
`

const Index = () => {
  const { homeJson } = useStaticQuery(homeQuery)
  const { cover, section, about, title } = homeJson
  return (
    <Layout>
      <SEO pageTitleFull={title} />
      <PageContainerStyles className="pagecontainer">
        <GatsbyImage
          image={cover.image.childImageSharp.gatsbyImageData}
          alt={cover.title}
        />
        <Section style={{ paddingTop: '0', lineHeight: '1' }}>
          <StyledImg
            image={section.image.childImageSharp.gatsbyImageData}
            alt="Photo of groomer with dog"
          />
          <BodyContainer>
            <h3>
              A State of the Art, Modern, Full Service Spa on wheels for your
              pet right at your doorstep!
            </h3>
            <br />
            <p>
              The Doggie Detailer offers a <em>Calm</em>,<em> Peaceful</em>, and{' '}
              <em>Positive </em> grooming environment. Only the
              <strong> BEST</strong> luxury products are used on our clients.
            </p>

            {/* <p /> */}
            <h3>Servicing Northern Monmouth County</h3>
            <p className="locations">
              Keyport, Matawan, Hazlet, Holmdel, Colts Neck & Middletown
            </p>
            <h2>Services starting at $125</h2>
          </BodyContainer>
        </Section>
        <StyledSectionTwo>
          <div
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{
              __html: about.content.childMarkdownRemark.html,
            }}
          />
        </StyledSectionTwo>
      </PageContainerStyles>
    </Layout>
  )
}

export default Index
